import React from 'react'
import Pagenotfound from './Pagenotfound'

export default function Chats() {
  return (
    <div>
        <Pagenotfound />
    </div>
  )
}
