import React from 'react'

export default function ProductSkeleton() {
    return (
        <div className='skull-products'>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            <div className="skull-prod-card" >
                <div className='skull-image-container'>

                </div>
                <div className="skull-product-name"></div>
                <div className="skull-product-price"></div>
                <div className="skull-product-add-btn"></div>
            </div>
            
           
        </div>
    )
}
